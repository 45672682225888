import React from 'react';

import { Card } from '@polyphonic/ui';

const Footer = () => {
  return (
    <Card
      className="grid-in-footer flex flex-row justify-center items-center rounded-none bg-neu m-0 lg:ml-5 lg:mr-2.5 lg:my-2.5 lg:rounded-xl shadow-none"
      as="footer"
    >
      <p className="text-center">
        Copyright © {new Date().getFullYear()} Polyphonic. All Rights Reserved.
      </p>
    </Card>
  );
};

export default Footer;
