const { nextui } = require('@nextui-org/react');
const defaultTheme = require('tailwindcss/defaultTheme');
const colors = require('@radix-ui/colors');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    boxShadow: {
      morphic:
        '5px 5px 10px hsl(var(--theme-neu)), -5px -5px 10px hsl(var(--theme-neu2))',
      morphicalt:
        '2px 2px 5px hsl(var(--theme-neu)), -2px -2px 5px hsl(var(--theme-neu2))',
      morphicinset:
        'inset 5px 5px 10px hsl(var(--theme-neu)), inset -5px -5px 10px hsl(var(--theme-neu2))',
    },
    extend: {
      gridTemplateAreas: {
        layout: ['header', 'main', 'footer'],
        'layout-lg': ['aside header', 'aside main', 'aside footer'],
        dashboard: ['card1', 'card2', 'card3'],
        'dashboard-lg': ['card1 card2', 'card1 card3'],
        settings: ['heading', 'card1', 'card2'],
        'settings-md': ['heading card2', 'card1 card2'],
        'settings-lg': ['heading', 'heading card1', 'heading card2'],
        links: ['card1', 'card2'],
        'links-sm': ['card1 card2'],
        agreements: ['card1', 'card2'],
        'agreements-sm': ['card1 card2'],
        distribution: ['card1', 'card2'],
        'distribution-sm': ['card1 card2'],
      },
      gridTemplateColumns: {
        layout: '1fr',
        'layout-lg': '240px 1fr',
        dashboard: '1fr',
        'dashboard-lg': '2fr 1fr',
        settings: '1fr',
        'settings-md': 'auto',
        'settings-lg': '1fr 2fr',
        links: '1fr',
        'links-sm': '1fr 2fr',
        agreements: '1fr',
        'agreements-sm': '1fr 2fr',
        distribution: '1fr',
        'distribution-sm': '1fr 2fr',
      },
      gridTemplateRows: {
        layout: '60px 1fr 100px',
        'layout-lg': '70px 1fr 80px',
        dashboard: '100px 200px 300px',
        'dashboard-lg': '200px 200px 300px',
        settings: 'auto',
        'settings-md': 'auto',
        'settings-lg': '1fr 12fr',
        links: 'auto',
        'links-sm': 'auto',
        agreements: 'auto',
        'agreements-sm': 'auto',
        distribution: 'auto',
        'distribution-sm': 'auto',
      },
      fontFamily: {
        sans: ['var(--theme-font-sans)', ...defaultTheme.fontFamily.sans],
        serif: [...defaultTheme.fontFamily.serif],
        mono: ['var(--theme-font-mono)', ...defaultTheme.fontFamily.mono],
        display: ['var(--theme-font-display)'],
      },
      keyframes: {
        neon: {
          from: {
            boxShadow:
              '0 0 5px #fff, 0 0 10px #fff, 0 0 15px hsl(var(--theme-primary)), 0 0 20px hsl(var(--theme-primary)), 0 0 25px hsl(var(--theme-primary)), 0 0 30px hsl(var(--theme-primary))',
          },
          to: {
            boxShadow:
              '0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff',
          },
        },
      },
      animation: {
        neon: 'neon 1s ease-in-out infinite alternate',
      },
    },
  },
  darkMode: 'class',
  plugins: [
    nextui({
      prefix: 'theme',
      layout: {},
      themes: {
        light: {
          colors: {
            background: colors.gray.gray5,
            success: colors.grass.grass9,
            danger: colors.tomato.tomato9,
            warning: colors.orange.orange9,
            content1: 'white',
            content2: colors.gray.gray8,
            neu: colors.gray.gray8,
            neu2: 'white',
            link: colors.pink.pink9,
            primary: {
              foreground: '#FFFFFF',
              DEFAULT: colors.violet.violet9,
            },
            secondary: {
              foreground: '#FFFFFF',
              DEFAULT: colors.blueDark.blue9,
            },
          },
        },
        dark: {
          colors: {
            background: colors.grayDark.gray3,
            success: colors.grassDark.grass9,
            danger: colors.tomatoDark.tomato9,
            warning: colors.orangeDark.orange9,
            content1: colors.grayDark.gray1,
            content2: colors.grayDark.gray5,
            neu: colors.grayDark.gray1,
            neu2: colors.grayDark.gray5,
            link: colors.pinkDark.pink9,
            primary: {
              foreground: '#FFFFFF',
              DEFAULT: colors.violetDark.violet9,
            },
            secondary: {
              foreground: '#FFFFFF',
              DEFAULT: colors.blueDark.blue9,
            },
          },
        },
      },
    }),
    require('@savvywombat/tailwindcss-grid-areas'),
  ],
  variants: {
    gridTemplateAreas: ['responsive'],
  },
};
