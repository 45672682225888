import { NextSeo } from 'next-seo';

import Footer from '../common/footer';
import Navigation from '../common/navigation';
import { Responsive } from '../shared/responsive';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const DefaultLayout = ({ title, children }: Props) => {
  return (
    <>
      <Navigation />
      <NextSeo title={title} />
      <main>{children}</main>
      <Footer />
      <Responsive />
    </>
  );
};
