import { NextSeo } from 'next-seo';

import { Responsive } from '../shared/responsive';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const FullLayout = ({ title, children }: Props) => {
  return (
    <>
      <NextSeo title={title} />
      <main>{children}</main>
      <Responsive />
    </>
  );
};
