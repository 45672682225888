const tailwindConfig = require('@polyphonic/tailwind/tailwind.config.js');

/* eslint-disable import/no-default-export, import/no-anonymous-default-export */
/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}',
  ],
  presets: [tailwindConfig],
};
