import { NextPage } from 'next';
import React from 'react';

interface Props {
  userAgent?: string;
}

// const FooterLink: NextPage<Props> = ({ children, ...props }) => (
//   <Link px={2} color="white" {...props}>
//     {children}
//   </Link>
// );

const Footer: NextPage<Props> = () => {
  return (
    <div className="flex flex-row justify-center align-center p-6 w-full bg-content2">
      <p className="font-display text-center font-semibold">
        © {new Date().getFullYear()} Polyphonic. All Rights Reserved.
      </p>
    </div>
  );
};
export default Footer;
