import { Button } from '@polyphonic/ui';

import { isDev } from '@/utils';

export function Responsive() {
  if (isDev()) {
    return (
      <div className="fixed bottom-3 right-3 w-fit z-50 opacity-50 padding-1">
        <Button
          isIconOnly
          radius="full"
          className="font-bold sm:block md:hidden lg:hidden xl:hidden 2xl:hidden"
        >
          sm
        </Button>
        <Button
          isIconOnly
          radius="full"
          className="font-bold hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden"
        >
          md
        </Button>
        <Button
          isIconOnly
          radius="full"
          className="font-bold hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden"
        >
          lg
        </Button>
        <Button
          isIconOnly
          radius="full"
          className="font-bold hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden"
        >
          xl
        </Button>
        <Button
          isIconOnly
          radius="full"
          className="font-bold hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block"
        >
          2xl
        </Button>
      </div>
    );
  }
  return null;
}
