import { Mumbai, Polygon } from '@thirdweb-dev/chains';
import { ConnectWallet } from '@thirdweb-dev/react';
import { useTheme } from 'next-themes';

import { notProd } from '@/utils';

export function Wallet() {
  const { theme } = useTheme();
  return (
    <ConnectWallet
      theme={theme === 'dark' ? 'dark' : 'light'}
      btnTitle="Connect"
      dropdownPosition={{
        side: 'bottom',
        align: 'center',
      }}
      auth={{
        loginOptional: false,
      }}
      hideSwitchToPersonalWallet={true}
      displayBalanceToken={
        notProd()
          ? {
              [Mumbai.chainId]: '0x9999f7Fea5938fD3b1E26A12c3f2fb024e194f97',
            }
          : {
              [Polygon.chainId]: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
            }
      }
      switchToActiveChain={true}
    />
  );
}
