import { NextSeo } from 'next-seo';

import Loader from '@/components/common';
import useAuth from '@/hooks/use-auth';
import Footer from '@/pages/dashboard/components/footer';
import Navigation from '@/pages/dashboard/components/navigation';

import { Responsive } from '../shared/responsive';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const DashboardLayout = ({ title, children }: Props) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Loader />;
  }
  return (
    <>
      <NextSeo title={title} />
      <div className="grid grid-areas-layout grid-cols-layout grid-rows-layout lg:grid-areas-layout-lg lg:grid-cols-layout-lg lg:grid-rows-layout-lg min-h-screen h-full">
        <Navigation title={title} />
        <main className="grid grid-in-main">{children}</main>
        <Footer />
      </div>
      <Responsive />
    </>
  );
};
