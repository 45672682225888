import { IconMoon, IconSun } from '@tabler/icons-react';
import { useTheme } from 'next-themes';
import React, { useEffect, useState } from 'react';

import { Button } from '@polyphonic/ui';

export const ThemeToggle = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();
  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);
  if (!mounted) return null;
  return (
    <Button
      isIconOnly
      radius="full"
      onClick={() => (theme === 'dark' ? setTheme('light') : setTheme('dark'))}
      aria-label="toggle a light and dark color scheme"
    >
      {theme === 'dark' ? (
        <IconMoon style={{ minWidth: '24px', minHeight: '24px' }} />
      ) : (
        <IconSun style={{ minWidth: '24px', minHeight: '24px' }} />
      )}
    </Button>
  );
};
