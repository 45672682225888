import { NextPage } from 'next';
import { useTheme } from 'next-themes';
import { Link as ScrollLink } from 'react-scroll';

import {
  Button,
  Image,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from '@polyphonic/ui';

import useAuth from '@/hooks/use-auth';

import { ThemeToggle } from './theme-toggle';

interface Props {
  userAgent?: string;
}

// const NavLink: NextPage<Props> = ({ children, ...props }) => (
//   <Link
//     fontFamily="urbane"
//     fontWeight={600}
//     px={4}
//     _hover={{ color: 'intent.link' }}
//     {...props}
//   >
//     {children}
//   </Link>
// );

const Navigation: NextPage<Props> = () => {
  const { isAuthenticated } = useAuth();
  const { theme } = useTheme();
  return (
    <>
      <Navbar isBordered maxWidth="full">
        <NavbarBrand>
          <Link href="/">
            <Image
              src={
                theme === 'light'
                  ? '/images/banner.svg'
                  : '/images/banner-dark.svg'
              }
              alt="logo"
              className="h-10 rounded-none"
            />
          </Link>
        </NavbarBrand>
        <NavbarContent justify="center">
          <ScrollLink
            activeClass="active"
            to="newsletter"
            spy={true}
            smooth={true}
            duration={500}
            aria-label="Sign Up"
          >
            Early Access
          </ScrollLink>
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem>
            <Link href={isAuthenticated ? '/dashboard' : '/auth'}>
              <Button radius="full" className="font-display font-semibold">
                {isAuthenticated ? 'Dashboard' : 'Sign In'}
              </Button>
            </Link>
          </NavbarItem>
          <NavbarItem>
            <ThemeToggle />
          </NavbarItem>
        </NavbarContent>
      </Navbar>
    </>
  );
};

export default Navigation;
